export type Menu = MenuItem[];

export interface MenuItem {
  label: string;
  caption?: string;
  to?: string;
  items?: MenuItem[];
}

export const NavigationMenu: Menu = [
  {
    label: 'Shooting',
    to: '/category/shooting',
    items: [
      {
        label: 'Guns',
        to: '/category/shooting/guns',
        items: [
          {
            label: 'Handguns',
            to: '/category/shooting/guns/handguns',
            items: [
              {
                label: 'Semi-Auto',
                to: '/category/shooting/guns/handguns/semi-auto',
              },
              {
                label: 'Revolver',
                to: '/category/shooting/guns/handguns/revolver',
              },
              {
                label: 'Single Shot',
                to: '/category/shooting/guns/handguns/single-shot',
              },
              {
                label: 'Black Powder',
                to: '/category/shooting/guns/handguns/black-powder',
              },
              {
                label: 'Lever Action',
                to: '/category/shooting/guns/handguns/lever-action',
              },
              {
                label: 'Bolt Action',
                to: '/category/shooting/guns/handguns/bolt-action',
              },
            ],
          },
          {
            label: 'Rifles',
            to: '/category/shooting/guns/rifles',
            items: [
              {
                label: 'Semi-Auto',
                to: '/category/shooting/guns/rifles/semi-auto',
              },
              {
                label: 'Bolt Action',
                to: '/category/shooting/guns/rifles/bolt-action',
              },
              {
                label: 'Pump Action',
                to: '/category/shooting/guns/rifles/pump-action',
              },
              {
                label: 'Single Shot',
                to: '/category/shooting/guns/rifles/single-shot',
              },
              {
                label: 'Revolver',
                to: '/category/shooting/guns/rifles/revolver',
              },
              {
                label: 'Lever Action',
                to: '/category/shooting/guns/rifles/lever-action',
              },
              {
                label: 'Black Powder',
                to: '/category/shooting/guns/rifles/black-powder',
              },
              {
                label: 'Break Open',
                to: '/category/shooting/guns/rifles/break-open',
              },
              {
                label: 'Over & Under',
                to: '/category/shooting/guns/rifles/over-and-under',
              },
              {
                label: 'Side By Side',
                to: '/category/shooting/guns/rifles/side-by-side',
              },
            ],
          },
          {
            label: 'Shotguns',
            to: '/category/shooting/guns/shotguns',
            items: [
              {
                label: 'Semi-Auto',
                to: '/category/shooting/guns/shotguns/semi-auto',
              },
              {
                label: 'Pump Action',
                to: '/category/shooting/guns/shotguns/pump-action',
              },
              {
                label: 'Over & Under',
                to: '/category/shooting/guns/shotguns/over-and-under',
              },
              {
                label: 'Single Shot',
                to: '/category/shooting/guns/shotguns/single-shot',
              },
              {
                label: 'Break Open',
                to: '/category/shooting/guns/shotguns/break-open',
              },
              {
                label: 'Side By Side',
                to: '/category/shooting/guns/shotguns/side-by-side',
              },
              {
                label: 'Lever Action',
                to: '/category/shooting/guns/shotguns/lever-action',
              },
              {
                label: 'Bolt Action',
                to: '/category/shooting/guns/shotguns/bolt-action',
              },
            ],
          },
          {
            label: 'Class III',
            to: '/category/shooting/guns/class-iii',
            items: [
              {
                label: 'Short Barrel Rifles',
                to: '/category/shooting/guns/class-iii/short-barrel-rifles',
              },
              {
                label: 'Short Barrel Shotguns',
                to: '/category/shooting/guns/class-iii/short-barrel-shotguns',
              },
              {
                label: 'Suppressed Rifles',
                to: '/category/shooting/guns/class-iii/suppressed-rifles',
              },
              {
                label: 'Suppressed Uppers',
                to: '/category/shooting/guns/class-iii/suppressed-uppers',
              },
              {
                label: 'Suppressors',
                to: '/category/shooting/guns/class-iii/suppressors',
              },
              {
                label: 'Suppressor Accessories',
                to: '/category/shooting/guns/class-iii/suppressor-accessories',
              },
            ],
          },
          {
            label: 'Air Guns',
            to: '/category/shooting/guns/air-guns',
            items: [
              {
                label: 'Air Pistols',
                to: '/category/shooting/guns/air-guns/air-pistols',
              },
              {
                label: 'Air Rifles',
                to: '/category/shooting/guns/air-guns/air-rifles',
              },
              {
                label: 'Air Gun Ammo',
                to: '/category/shooting/guns/air-guns/air-gun-ammo',
                items: [
                  {
                    label: 'Pellets',
                    to: '/category/shooting/guns/air-guns/air-gun-ammo/pellets',
                  },
                  {
                    label: 'BBs',
                    to: '/category/shooting/guns/air-guns/air-gun-ammo/bbs',
                  },
                  {
                    label: 'Slugs',
                    to: '/category/shooting/guns/air-guns/air-gun-ammo/slugs',
                  },
                ],
              },
              {
                label: 'Air Gun Optics',
                to: '/category/shooting/guns/air-guns/air-gun-optics',
                items: [
                  {
                    label: 'Air Gun Scopes',
                    to: '/category/shooting/guns/air-guns/air-gun-optics/air-gun-scopes',
                  },
                  {
                    label: 'Air Gun Rings & Mounts',
                    to: '/category/shooting/guns/air-guns/air-gun-optics/air-gun-rings-and-mounts',
                  },
                  {
                    label: 'Air Gun Sights & Lasers',
                    to: '/category/shooting/guns/air-guns/air-gun-optics/air-gun-sights-and-lasers',
                  },
                ],
              },
              {
                label: 'Air Gun Accessories',
                to: '/category/shooting/guns/air-guns/air-gun-accessories',
                items: [
                  {
                    label: 'Magazines',
                    to: '/category/shooting/guns/air-guns/air-gun-accessories/magazines',
                  },
                  {
                    label: 'Speed Loaders & Holders',
                    to: '/category/shooting/guns/air-guns/air-gun-accessories/speed-loaders-and-holders',
                  },
                  {
                    label: 'CO2 & PCP',
                    to: '/category/shooting/guns/air-guns/air-gun-accessories/co2-and-pcp',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'Muzzleloaders & Powder',
        to: '/category/shooting/muzzleloaders-and-powder',
        items: [
          {
            label: 'Muzzleloaders',
            to: '/category/shooting/muzzleloaders-and-powder/muzzleloaders',
          },
          {
            label: 'Black Powder Handguns',
            to: '/category/shooting/muzzleloaders-and-powder/black-powder-handguns',
          },
          {
            label: 'Powder & Primers',
            to: '/category/shooting/muzzleloaders-and-powder/powder-and-primers',
          },
          {
            label: 'Bullets & Sabots',
            to: '/category/shooting/muzzleloaders-and-powder/bullets-and-sabots',
          },
          {
            label: 'Muzzleloader Parts',
            to: '/category/shooting/muzzleloaders-and-powder/muzzleloader-parts',
          },
          {
            label: 'Black Powder Accessories',
            to: '/category/shooting/muzzleloaders-and-powder/black-powder-accessories',
          },
          {
            label: 'Black Powder Cleaning',
            to: '/category/shooting/muzzleloaders-and-powder/black-powder-cleaning',
          },
        ],
      },
      {
        label: 'Gun Parts',
        to: '/category/shooting/gun-parts',
        items: [
          {
            label: 'Magazines',
            to: '/category/shooting/gun-parts/magazines',
            items: [
              {
                label: 'Handgun Magazines',
                to: '/category/shooting/gun-parts/magazines/handgun-magazines',
              },
              {
                label: 'Rifle Magazines',
                to: '/category/shooting/gun-parts/magazines/rifle-magazines',
              },
              {
                label: 'Rimfire Magazines',
                to: '/category/shooting/gun-parts/magazines/rimfire-magazines',
              },
              {
                label: 'Shotgun Magazines',
                to: '/category/shooting/gun-parts/magazines/shotgun-magazines',
              },
              {
                label: 'Speed Loaders & More',
                to: '/category/shooting/gun-parts/magazines/speed-loaders-and-more',
              },
            ],
          },
          {
            label: 'Handgun & Rifle Parts',
            to: '/category/shooting/gun-parts/handgun-and-rifle-parts',
            items: [
              {
                label: 'Handgun Parts',
                to: '/category/shooting/gun-parts/handgun-and-rifle-parts/handgun-parts',
                items: [
                  {
                    label: 'Handgun Barrels',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/handgun-parts/handgun-barrels',
                  },
                  {
                    label: 'Handgun Grips & Frames',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/handgun-parts/handgun-grips-and-frames',
                  },
                  {
                    label: 'Handgun Triggers',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/handgun-parts/handgun-triggers',
                  },
                  {
                    label: 'Handgun Attachments',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/handgun-parts/handgun-attachments',
                  },
                ],
              },
              {
                label: 'Rifle Parts',
                to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts',
                items: [
                  {
                    label: 'Bolt Carrier Group',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/bolt-carrier-group',
                  },
                  {
                    label: 'Upper Receiver Parts',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/upper-receiver-parts',
                  },
                  {
                    label: 'Receivers & Lowers',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/receivers-and-lowers',
                  },
                  {
                    label: 'Stocks & Recoil Pads',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/stocks-and-recoil-pads',
                  },
                  {
                    label: 'Rifle Grips',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/rifle-grips',
                  },
                  {
                    label: 'Rifle Attachments',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/rifle-attachments',
                  },
                  {
                    label: 'Upper Receivers',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/upper-receivers',
                  },
                  {
                    label: 'Lower Parts',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/lower-parts',
                  },
                  {
                    label: 'Rifle Triggers',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/rifle-triggers',
                  },
                  {
                    label: 'Rifle Barrels',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/rifle-barrels',
                  },
                  {
                    label: 'Rails & Handguards',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/rails-and-handguards',
                  },
                  {
                    label: 'Gas Blocks',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/gas-blocks',
                  },
                  {
                    label: 'Muzzle Brakes & Devices',
                    to: '/category/shooting/gun-parts/handgun-and-rifle-parts/rifle-parts/muzzle-brakes-and-devices',
                  },
                ],
              },
              {
                label: 'Slings & Swivels',
                to: '/category/shooting/gun-parts/handgun-and-rifle-parts/slings-and-swivels',
              },
            ],
          },
          {
            label: 'Sights, Lasers, & Lights',
            to: '/category/shooting/gun-parts/sights-lasers-and-lights',
            items: [
              {
                label: 'Gun Sights',
                to: '/category/shooting/gun-parts/sights-lasers-and-lights/gun-sights',
              },
              {
                label: 'Gun Lasers',
                to: '/category/shooting/gun-parts/sights-lasers-and-lights/gun-lasers',
              },
              {
                label: 'Weapon Lights',
                to: '/category/shooting/gun-parts/sights-lasers-and-lights/weapon-lights',
              },
            ],
          },
          {
            label: 'Gun Tools',
            to: '/category/shooting/gun-parts/gun-tools',
          },
          {
            label: 'Choke Tubes',
            to: '/category/shooting/gun-parts/choke-tubes',
          },
        ],
      },
      {
        label: 'Gun Accessories',
        to: '/category/shooting/gun-accessories',
        items: [
          {
            label: 'Holsters & Belts',
            to: '/category/shooting/gun-accessories/holsters-and-belts',
            items: [
              {
                label: 'Inside The Waistband',
                to: '/category/shooting/gun-accessories/holsters-and-belts/inside-the-waistband',
              },
              {
                label: 'Outside The Waistband',
                to: '/category/shooting/gun-accessories/holsters-and-belts/outside-the-waistband',
              },
              {
                label: 'Shoulder Holsters',
                to: '/category/shooting/gun-accessories/holsters-and-belts/shoulder-holsters',
              },
              {
                label: 'Ankle Holsters',
                to: '/category/shooting/gun-accessories/holsters-and-belts/ankle-holsters',
              },
              {
                label: 'Pocket Holsters',
                to: '/category/shooting/gun-accessories/holsters-and-belts/pocket-holsters',
              },
              {
                label: 'Thigh Holsters',
                to: '/category/shooting/gun-accessories/holsters-and-belts/thigh-holsters',
              },
              {
                label: 'Magazine Holsters',
                to: '/category/shooting/gun-accessories/holsters-and-belts/magazine-holsters',
              },
              {
                label: 'Holster Accessories',
                to: '/category/shooting/gun-accessories/holsters-and-belts/holster-accessories',
              },
              {
                label: 'Belts',
                to: '/category/shooting/gun-accessories/holsters-and-belts/belts',
              },
            ],
          },
          {
            label: 'Range Bags',
            to: '/category/shooting/gun-accessories/range-bags',
          },
          {
            label: 'Concealed Carry Handbags',
            to: '/category/shooting/gun-accessories/concealed-carry-handbags',
          },
          {
            label: 'Gun Cleaning',
            to: '/category/shooting/gun-accessories/gun-cleaning',
            items: [
              {
                label: 'Gun Cleaning Kits',
                to: '/category/shooting/gun-accessories/gun-cleaning/gun-cleaning-kits',
              },
              {
                label: 'Cleaning Rods & Snakes',
                to: '/category/shooting/gun-accessories/gun-cleaning/cleaning-rods-and-snakes',
              },
              {
                label: 'Cleaners & Lubricants',
                to: '/category/shooting/gun-accessories/gun-cleaning/cleaners-and-lubricants',
              },
              {
                label: 'Brushes, Jags, & Mops',
                to: '/category/shooting/gun-accessories/gun-cleaning/brushes-jags-and-mops',
              },
              {
                label: 'Gun Cleaning Accessories',
                to: '/category/shooting/gun-accessories/gun-cleaning/gun-cleaning-accessories',
              },
            ],
          },
          {
            label: 'Eye & Ear Protection',
            to: '/category/shooting/gun-accessories/eye-and-ear-protection',
            items: [
              {
                label: 'Ear Protection',
                to: '/category/shooting/gun-accessories/eye-and-ear-protection/ear-protection',
              },
              {
                label: 'Eye Protection',
                to: '/category/shooting/gun-accessories/eye-and-ear-protection/eye-protection',
              },
            ],
          },
          {
            label: 'Benches, Rests, & Bags',
            to: '/category/shooting/gun-accessories/benches-rests-and-bags',
          },
          {
            label: 'Bipods, Tripods, & Sticks',
            to: '/category/shooting/gun-accessories/bipods-tripods-and-sticks',
          },
          {
            label: 'Targets & Trap Throwers',
            to: '/category/shooting/gun-accessories/targets-and-trap-throwers',
            items: [
              {
                label: 'Shooting Targets & Stands',
                to: '/category/shooting/gun-accessories/targets-and-trap-throwers/shooting-targets-and-stands',
              },
              {
                label: 'Clay Targets',
                to: '/category/shooting/gun-accessories/targets-and-trap-throwers/clay-targets',
              },
              {
                label: 'Clay Target Throwers',
                to: '/category/shooting/gun-accessories/targets-and-trap-throwers/clay-target-throwers',
              },
            ],
          },
        ],
      },
      {
        label: 'Gun Storage',
        to: '/category/shooting/gun-storage',
        items: [
          {
            label: 'Gun Cases & Locks',
            to: '/category/shooting/gun-storage/gun-cases-and-locks',
            items: [
              {
                label: 'Handgun Cases',
                to: '/category/shooting/gun-storage/gun-cases-and-locks/handgun-cases',
              },
              {
                label: 'Rifle Cases',
                to: '/category/shooting/gun-storage/gun-cases-and-locks/rifle-cases',
              },
              {
                label: 'Shotgun Cases',
                to: '/category/shooting/gun-storage/gun-cases-and-locks/shotgun-cases',
              },
              {
                label: 'Gun Locks',
                to: '/category/shooting/gun-storage/gun-cases-and-locks/gun-locks',
              },
            ],
          },
          {
            label: 'Gun Safes & Cabinets',
            to: '/category/shooting/gun-storage/gun-safes-and-cabinets',
          },
          {
            label: 'Gun Safe Accessories',
            to: '/category/shooting/gun-storage/gun-safe-accessories',
          },
        ],
      },
    ],
  },
  {
    label: 'Ammunition',
    to: '/category/ammunition',
    items: [
      {
        label: 'Handgun Ammo',
        to: '/category/ammunition/handgun-ammo',
      },
      {
        label: 'Rifle Ammo',
        to: '/category/ammunition/rifle-ammo',
      },
      {
        label: 'Shotgun Ammo',
        to: '/category/ammunition/shotgun-ammo',
      },
      {
        label: 'Rimfire Ammo',
        to: '/category/ammunition/rimfire-ammo',
      },
      {
        label: 'Bulk Ammo',
        to: '/category/ammunition/bulk-ammo',
      },
      {
        label: 'Ammo Boxes & Cans',
        to: '/category/ammunition/ammo-boxes-and-cans',
      },
      {
        label: 'Reloading Ammunition',
        to: '/category/ammunition/reloading-ammunition',
        items: [
          {
            label: 'Primers',
            to: '/category/ammunition/reloading-ammunition/primers',
          },
          {
            label: 'Powder',
            to: '/category/ammunition/reloading-ammunition/powder',
          },
          {
            label: 'Bullets',
            to: '/category/ammunition/reloading-ammunition/bullets',
          },
          {
            label: 'Brass',
            to: '/category/ammunition/reloading-ammunition/brass',
          },
          {
            label: 'Shot & Wads',
            to: '/category/ammunition/reloading-ammunition/shot-and-wads',
          },
          {
            label: 'Reloading Presses & Kits',
            to: '/category/ammunition/reloading-ammunition/reloading-presses-and-kits',
          },
          {
            label: 'Reloading Dies',
            to: '/category/ammunition/reloading-ammunition/reloading-dies',
          },
          {
            label: 'Reloading Accessories',
            to: '/category/ammunition/reloading-ammunition/reloading-accessories',
          },
          {
            label: 'Reloading Tools',
            to: '/category/ammunition/reloading-ammunition/reloading-tools',
          },
          {
            label: 'Case Preparation',
            to: '/category/ammunition/reloading-ammunition/case-preparation',
          },
          {
            label: 'Shell Holders & More',
            to: '/category/ammunition/reloading-ammunition/shell-holders-and-more',
          },
          {
            label: 'Powder Measures & Scales',
            to: '/category/ammunition/reloading-ammunition/powder-measures-and-scales',
          },
          {
            label: 'Reloading Books & Manuals',
            to: '/category/ammunition/reloading-ammunition/reloading-books-and-manuals',
          },
        ],
      },
    ],
  },
  {
    label: 'Hunting',
    to: '/category/hunting',
    items: [
      {
        label: 'Hunting Accessories',
        to: '/category/hunting/hunting-accessories',
        items: [
          {
            label: 'Radios & GPS',
            to: '/category/hunting/hunting-accessories/radios-and-gps',
          },
          {
            label: 'Ground Blinds',
            to: '/category/hunting/hunting-accessories/ground-blinds',
          },
          {
            label: 'Scent Elimination',
            to: '/category/hunting/hunting-accessories/scent-elimination',
          },
          {
            label: 'Scents',
            to: '/category/hunting/hunting-accessories/scents',
          },
          {
            label: 'Trapping',
            to: '/category/hunting/hunting-accessories/trapping',
          },
          {
            label: 'Waterfowl',
            to: '/category/hunting/hunting-accessories/waterfowl',
          },
          {
            label: 'Taxidermy',
            to: '/category/hunting/hunting-accessories/taxidermy',
          },
          {
            label: 'Harvest & Game Carts',
            to: '/category/hunting/hunting-accessories/harvest-and-game-carts',
          },
          {
            label: 'Chairs & Cushions',
            to: '/category/hunting/hunting-accessories/chairs-and-cushions',
          },
          {
            label: 'Game Ears & Hearing Aids',
            to: '/category/hunting/hunting-accessories/game-ears-and-hearing-aids',
          },
          {
            label: 'Accessories',
            to: '/category/hunting/hunting-accessories/accessories',
          },
        ],
      },
      {
        label: 'Packs & Bags',
        to: '/category/hunting/packs-and-bags',
      },
      {
        label: 'Tree Stands',
        to: '/category/hunting/tree-stands',
        items: [
          {
            label: 'Ladder Stands',
            to: '/category/hunting/tree-stands/ladder-stands',
          },
          {
            label: 'Fixed Position Stands',
            to: '/category/hunting/tree-stands/fixed-position-stands',
          },
          {
            label: 'Climbing Stands',
            to: '/category/hunting/tree-stands/climbing-stands',
          },
          {
            label: 'Box Blinds & Tripods',
            to: '/category/hunting/tree-stands/box-blinds-and-tripods',
          },
          {
            label: 'Stick Ladders & Steps',
            to: '/category/hunting/tree-stands/stick-ladders-and-steps',
          },
          {
            label: 'Safety Harnesses',
            to: '/category/hunting/tree-stands/safety-harnesses',
          },
          {
            label: 'Saddles',
            to: '/category/hunting/tree-stands/saddles',
          },
          {
            label: 'Accessories',
            to: '/category/hunting/tree-stands/accessories',
          },
        ],
      },
      {
        label: 'Trail Cameras',
        to: '/category/hunting/trail-cameras',
        items: [
          {
            label: 'Cameras',
            to: '/category/hunting/trail-cameras/cameras',
          },
          {
            label: 'Accessories',
            to: '/category/hunting/trail-cameras/accessories',
          },
        ],
      },
      {
        label: 'Food Plots & Feeding',
        to: '/category/hunting/food-plots-and-feeding',
        items: [
          {
            label: 'Feed & Minerals',
            to: '/category/hunting/food-plots-and-feeding/feed-and-minerals',
          },
          {
            label: 'Seeds & Accessories',
            to: '/category/hunting/food-plots-and-feeding/seeds-and-accessories',
          },
          {
            label: 'Game Feeders',
            to: '/category/hunting/food-plots-and-feeding/game-feeders',
          },
        ],
      },
      {
        label: 'Game Calls',
        to: '/category/hunting/game-calls',
        items: [
          {
            label: 'Turkey',
            to: '/category/hunting/game-calls/turkey',
          },
          {
            label: 'Waterfowl',
            to: '/category/hunting/game-calls/waterfowl',
          },
          {
            label: 'Big Game',
            to: '/category/hunting/game-calls/big-game',
          },
          {
            label: 'Small Game',
            to: '/category/hunting/game-calls/small-game',
          },
          {
            label: 'Predator',
            to: '/category/hunting/game-calls/predator',
          },
        ],
      },
      {
        label: 'Decoys',
        to: '/category/hunting/decoys',
        items: [
          {
            label: 'Turkey',
            to: '/category/hunting/decoys/turkey',
          },
          {
            label: 'Waterfowl',
            to: '/category/hunting/decoys/waterfowl',
          },
          {
            label: 'Big Game',
            to: '/category/hunting/decoys/big-game',
          },
          {
            label: 'Small Game',
            to: '/category/hunting/decoys/small-game',
          },
          {
            label: 'Predator',
            to: '/category/hunting/decoys/predator',
          },
          {
            label: 'Accessories',
            to: '/category/hunting/decoys/accessories',
          },
        ],
      },
      {
        label: 'Dog Training',
        to: '/category/hunting/dog-training',
        items: [
          {
            label: 'Electronic Dog Training',
            to: '/category/hunting/dog-training/electronic-dog-training',
          },
          {
            label: 'Training Supplies',
            to: '/category/hunting/dog-training/training-supplies',
          },
          {
            label: 'Bedding & Shelters',
            to: '/category/hunting/dog-training/bedding-and-shelters',
          },
          {
            label: 'Accessories',
            to: '/category/hunting/dog-training/accessories',
          },
        ],
      },
      {
        label: 'Game & Food Processing',
        to: '/category/hunting/game-and-food-processing',
        items: [
          {
            label: 'Meat Grinders',
            to: '/category/hunting/game-and-food-processing/meat-grinders',
          },
          {
            label: 'Vacuum Sealers',
            to: '/category/hunting/game-and-food-processing/vacuum-sealers',
          },
          {
            label: 'Dehydrators',
            to: '/category/hunting/game-and-food-processing/dehydrators',
          },
          {
            label: 'Slicers',
            to: '/category/hunting/game-and-food-processing/slicers',
          },
          {
            label: 'Sausage Stuffers',
            to: '/category/hunting/game-and-food-processing/sausage-stuffers',
          },
          {
            label: 'Accessories',
            to: '/category/hunting/game-and-food-processing/accessories',
          },
        ],
      },
    ],
  },
  {
    label: 'Optics',
    to: '/category/optics',
    items: [
      {
        label: 'Scopes',
        to: '/category/optics/scopes',
        items: [
          {
            label: 'Handgun Scopes',
            to: '/category/optics/scopes/handgun-scopes',
          },
          {
            label: 'Rifle Scopes',
            to: '/category/optics/scopes/rifle-scopes',
          },
        ],
      },
      {
        label: 'Binoculars',
        to: '/category/optics/binoculars',
      },
      {
        label: 'Spotting Scopes',
        to: '/category/optics/spotting-scopes',
      },
      {
        label: 'Monoculars',
        to: '/category/optics/monoculars',
      },
      {
        label: 'Red Dots',
        to: '/category/optics/red-dots',
      },
      {
        label: 'Magnifiers',
        to: '/category/optics/magnifiers',
      },
      {
        label: 'Rangefinders',
        to: '/category/optics/rangefinders',
      },
      {
        label: 'Thermal & Night Vision',
        to: '/category/optics/thermal-and-night-vision',
      },
      {
        label: 'Scope Rings & Bases',
        to: '/category/optics/scope-rings-and-bases',
        items: [
          {
            label: 'Rings',
            to: '/category/optics/scope-rings-and-bases/rings',
          },
          {
            label: 'Bases',
            to: '/category/optics/scope-rings-and-bases/bases',
          },
          {
            label: 'Cantilever Mounts',
            to: '/category/optics/scope-rings-and-bases/cantilever-mounts',
          },
        ],
      },
      {
        label: 'Scope Caps & Accessories',
        to: '/category/optics/scope-caps-and-accessories',
      },
      {
        label: 'Tripods, Mounts, & More',
        to: '/category/optics/tripods-mounts-and-more',
      },
      {
        label: 'Bino Harnesses & Cases',
        to: '/category/optics/bino-harnesses-and-cases',
      },
    ],
  },
  {
    label: 'Knives',
    to: '/category/knives',
    items: [
      {
        label: 'Automatic Knives',
        to: '/category/knives/automatic-knives',
      },
      {
        label: 'Folding Knives',
        to: '/category/knives/folding-knives',
      },
      {
        label: 'Fixed Blade Knives',
        to: '/category/knives/fixed-blade-knives',
      },
      {
        label: 'Kitchen Cutlery',
        to: '/category/knives/kitchen-cutlery',
      },
      {
        label: 'Replacement Blades',
        to: '/category/knives/replacement-blades',
      },
      {
        label: 'Accessories',
        to: '/category/knives/accessories',
      },
      {
        label: 'Saws, Machetes, & Axes',
        to: '/category/knives/saws-machetes-and-axes',
        items: [
          {
            label: 'Saws',
            to: '/category/knives/saws-machetes-and-axes/saws',
          },
          {
            label: 'Axes & Hatchets',
            to: '/category/knives/saws-machetes-and-axes/axes-and-hatchets',
          },
          {
            label: 'Shovels',
            to: '/category/knives/saws-machetes-and-axes/shovels',
          },
          {
            label: 'Machetes',
            to: '/category/knives/saws-machetes-and-axes/machetes',
          },
          {
            label: 'Defense',
            to: '/category/knives/saws-machetes-and-axes/defense',
          },
        ],
      },
      {
        label: 'Knife Sharpeners',
        to: '/category/knives/knife-sharpeners',
        items: [
          {
            label: 'Electric Knife Sharpeners',
            to: '/category/knives/knife-sharpeners/electric-knife-sharpeners',
          },
          {
            label: 'Manual Knife Sharpeners',
            to: '/category/knives/knife-sharpeners/manual-knife-sharpeners',
          },
        ],
      },
      {
        label: 'Multi-Tools',
        to: '/category/knives/multi-tools',
      },
    ],
  },
  {
    label: 'Archery',
    to: '/category/archery',
    items: [
      {
        label: 'Bows',
        to: '/category/archery/bows',
        items: [
          {
            label: 'Compound Bows',
            to: '/category/archery/bows/compound-bows',
          },
          {
            label: 'Recurve Bows',
            to: '/category/archery/bows/recurve-bows',
          },
          {
            label: 'Longbows',
            to: '/category/archery/bows/longbows',
          },
          {
            label: 'Youth Bows',
            to: '/category/archery/bows/youth-bows',
          },
          {
            label: 'Crossbows',
            to: '/category/archery/bows/crossbows',
          },
          {
            label: 'Bowfishing',
            to: '/category/archery/bows/bowfishing',
          },
        ],
      },
      {
        label: 'Bow Accessories',
        to: '/category/archery/bow-accessories',
        items: [
          {
            label: 'Sights & Scopes',
            to: '/category/archery/bow-accessories/sights-and-scopes',
          },
          {
            label: 'Arrow Rests & Plungers',
            to: '/category/archery/bow-accessories/arrow-rests-and-plungers',
          },
          {
            label: 'Stabilizers & Dampers',
            to: '/category/archery/bow-accessories/stabilizers-and-dampers',
          },
          {
            label: 'Peep Sights & Accessories',
            to: '/category/archery/bow-accessories/peep-sights-and-accessories',
          },
          {
            label: 'Bowstrings & Material',
            to: '/category/archery/bow-accessories/bowstrings-and-material',
          },
          {
            label: 'Bow Cases & Covers',
            to: '/category/archery/bow-accessories/bow-cases-and-covers',
          },
          {
            label: 'Crossbow Accessories',
            to: '/category/archery/bow-accessories/crossbow-accessories',
          },
          {
            label: 'Bowfishing Accessories',
            to: '/category/archery/bow-accessories/bowfishing-accessories',
          },
          {
            label: 'Bow Hardware & Parts',
            to: '/category/archery/bow-accessories/bow-hardware-and-parts',
          },
        ],
      },
      {
        label: 'Arrows',
        to: '/category/archery/arrows',
        items: [
          {
            label: 'Arrow Shafts',
            to: '/category/archery/arrows/arrow-shafts',
          },
          {
            label: 'Fletched Arrows',
            to: '/category/archery/arrows/fletched-arrows',
          },
          {
            label: 'Crossbow Bolts & Arrows',
            to: '/category/archery/arrows/crossbow-bolts-and-arrows',
          },
          {
            label: 'Bowfishing Arrows',
            to: '/category/archery/arrows/bowfishing-arrows',
          },
          {
            label: 'Arrow Components',
            to: '/category/archery/arrows/arrow-components',
            items: [
              {
                label: 'Nocks',
                to: '/category/archery/arrows/arrow-components/nocks',
              },
              {
                label: 'Lighted Nocks',
                to: '/category/archery/arrows/arrow-components/lighted-nocks',
              },
              {
                label: 'Crossbow Nocks',
                to: '/category/archery/arrows/arrow-components/crossbow-nocks',
              },
              {
                label: 'Nock Pins & Bushings',
                to: '/category/archery/arrows/arrow-components/nock-pins-and-bushings',
              },
              {
                label: 'Nock Collars',
                to: '/category/archery/arrows/arrow-components/nock-collars',
              },
              {
                label: 'Vanes',
                to: '/category/archery/arrows/arrow-components/vanes',
              },
              {
                label: 'Feathers',
                to: '/category/archery/arrows/arrow-components/feathers',
              },
              {
                label: 'Glue-In Points',
                to: '/category/archery/arrows/arrow-components/glue-in-points',
              },
              {
                label: 'Screw-In Points',
                to: '/category/archery/arrows/arrow-components/screw-in-points',
              },
              {
                label: 'Glue-On Points',
                to: '/category/archery/arrows/arrow-components/glue-on-points',
              },
              {
                label: 'Inserts & Outserts',
                to: '/category/archery/arrows/arrow-components/inserts-and-outserts',
              },
              {
                label: 'Point & Insert Weights',
                to: '/category/archery/arrows/arrow-components/point-and-insert-weights',
              },
              {
                label: 'Arrow Wraps & Numbers',
                to: '/category/archery/arrows/arrow-components/arrow-wraps-and-numbers',
              },
            ],
          },
          {
            label: 'Arrow Building & Repair',
            to: '/category/archery/arrows/arrow-building-and-repair',
            items: [
              {
                label: 'Adhesives, Glues, & Epoxy',
                to: '/category/archery/arrows/arrow-building-and-repair/adhesives-glues-and-epoxy',
              },
              {
                label: 'Fletching Jigs',
                to: '/category/archery/arrows/arrow-building-and-repair/fletching-jigs',
              },
              {
                label: 'Arrow Building & Tools',
                to: '/category/archery/arrows/arrow-building-and-repair/arrow-building-and-tools',
              },
              {
                label: 'Arrow Cut-Off Saws',
                to: '/category/archery/arrows/arrow-building-and-repair/arrow-cut-off-saws',
              },
              {
                label: 'Grain Scales & Spine Test',
                to: '/category/archery/arrows/arrow-building-and-repair/grain-scales-and-spine-test',
              },
              {
                label: 'Feather Cutters',
                to: '/category/archery/arrows/arrow-building-and-repair/feather-cutters',
              },
              {
                label: 'Feather Treatment',
                to: '/category/archery/arrows/arrow-building-and-repair/feather-treatment',
              },
            ],
          },
          {
            label: 'Arrow Tubes & Cases',
            to: '/category/archery/arrows/arrow-tubes-and-cases',
          },
          {
            label: 'Broadheads & Field Points',
            to: '/category/archery/arrows/broadheads-and-field-points',
            items: [
              {
                label: 'Fixed Blade Broadheads',
                to: '/category/archery/arrows/broadheads-and-field-points/fixed-blade-broadheads',
              },
              {
                label: 'Expandable Broadheads',
                to: '/category/archery/arrows/broadheads-and-field-points/expandable-broadheads',
              },
              {
                label: 'Hybrid Broadheads',
                to: '/category/archery/arrows/broadheads-and-field-points/hybrid-broadheads',
              },
              {
                label: 'Crossbow Broadheads',
                to: '/category/archery/arrows/broadheads-and-field-points/crossbow-broadheads',
              },
              {
                label: 'Traditional Broadheads',
                to: '/category/archery/arrows/broadheads-and-field-points/traditional-broadheads',
              },
              {
                label: 'Replacement Blades',
                to: '/category/archery/arrows/broadheads-and-field-points/replacement-blades',
              },
              {
                label: 'Small Game Points',
                to: '/category/archery/arrows/broadheads-and-field-points/small-game-points',
              },
              {
                label: 'Bowfishing Points',
                to: '/category/archery/arrows/broadheads-and-field-points/bowfishing-points',
              },
              {
                label: 'Blunt Points',
                to: '/category/archery/arrows/broadheads-and-field-points/blunt-points',
              },
              {
                label: 'Sharpeners & Tools',
                to: '/category/archery/arrows/broadheads-and-field-points/sharpeners-and-tools',
              },
              {
                label: 'Broadhead Storage',
                to: '/category/archery/arrows/broadheads-and-field-points/broadhead-storage',
              },
            ],
          },
        ],
      },
      {
        label: 'Archery Accessories',
        to: '/category/archery/archery-accessories',
        items: [
          {
            label: 'Releases & Accessories',
            to: '/category/archery/archery-accessories/releases-and-accessories',
            items: [
              {
                label: 'Handheld Trigger Releases',
                to: '/category/archery/archery-accessories/releases-and-accessories/handheld-trigger-releases',
              },
              {
                label: 'Back Tension Releases',
                to: '/category/archery/archery-accessories/releases-and-accessories/back-tension-releases',
              },
              {
                label: 'Resistance Activated',
                to: '/category/archery/archery-accessories/releases-and-accessories/resistance-activated',
              },
              {
                label: 'Index Finger Releases',
                to: '/category/archery/archery-accessories/releases-and-accessories/index-finger-releases',
              },
              {
                label: 'Release Bags',
                to: '/category/archery/archery-accessories/releases-and-accessories/release-bags',
              },
              {
                label: 'Release Straps',
                to: '/category/archery/archery-accessories/releases-and-accessories/release-straps',
              },
            ],
          },
          {
            label: 'Tabs, Slings, & Guards',
            to: '/category/archery/archery-accessories/tabs-slings-and-guards',
            items: [
              {
                label: 'Finger Tabs',
                to: '/category/archery/archery-accessories/tabs-slings-and-guards/finger-tabs',
              },
              {
                label: 'Finger Tab Accessories',
                to: '/category/archery/archery-accessories/tabs-slings-and-guards/finger-tab-accessories',
              },
              {
                label: 'Wrist & Finger Slings',
                to: '/category/archery/archery-accessories/tabs-slings-and-guards/wrist-and-finger-slings',
              },
              {
                label: 'Armguards',
                to: '/category/archery/archery-accessories/tabs-slings-and-guards/armguards',
              },
              {
                label: 'Chest Guards & Protectors',
                to: '/category/archery/archery-accessories/tabs-slings-and-guards/chest-guards-and-protectors',
              },
              {
                label: 'Shooting Gloves',
                to: '/category/archery/archery-accessories/tabs-slings-and-guards/shooting-gloves',
              },
            ],
          },
          {
            label: 'Quivers, Belts & Carriers',
            to: '/category/archery/archery-accessories/quivers-belts-and-carriers',
            items: [
              {
                label: 'Target & 3D Quivers',
                to: '/category/archery/archery-accessories/quivers-belts-and-carriers/target-and-3d-quivers',
              },
              {
                label: 'Traditional Back Quivers',
                to: '/category/archery/archery-accessories/quivers-belts-and-carriers/traditional-back-quivers',
              },
              {
                label: 'Youth Quivers',
                to: '/category/archery/archery-accessories/quivers-belts-and-carriers/youth-quivers',
              },
              {
                label: 'Quiver Belts',
                to: '/category/archery/archery-accessories/quivers-belts-and-carriers/quiver-belts',
              },
              {
                label: 'Quiver & Belt Accessories',
                to: '/category/archery/archery-accessories/quivers-belts-and-carriers/quiver-and-belt-accessories',
              },
              {
                label: 'Bow Rests & Carriers',
                to: '/category/archery/archery-accessories/quivers-belts-and-carriers/bow-rests-and-carriers',
              },
              {
                label: 'Hunting Quivers',
                to: '/category/archery/archery-accessories/quivers-belts-and-carriers/hunting-quivers',
              },
            ],
          },
        ],
      },
      {
        label: 'Archery Targets',
        to: '/category/archery/archery-targets',
        items: [
          {
            label: 'Block & Foam Targets',
            to: '/category/archery/archery-targets/block-and-foam-targets',
          },
          {
            label: 'Bag Targets',
            to: '/category/archery/archery-targets/bag-targets',
          },
          {
            label: '3D Targets',
            to: '/category/archery/archery-targets/3d-targets',
          },
        ],
      },
    ],
  },
  {
    label: 'Clothing',
    to: '/category/clothing',
    items: [
      {
        label: 'Men\u0027s Clothing',
        to: '/category/clothing/mens-clothing',
        items: [
          {
            label: 'Jackets & Outerwear',
            to: '/category/clothing/mens-clothing/jackets-and-outerwear',
          },
          {
            label: 'Shirts & Tops',
            to: '/category/clothing/mens-clothing/shirts-and-tops',
          },
          {
            label: 'Pants & Shorts',
            to: '/category/clothing/mens-clothing/pants-and-shorts',
          },
          {
            label: 'Hats',
            to: '/category/clothing/mens-clothing/hats',
          },
          {
            label: 'Accessories',
            to: '/category/clothing/mens-clothing/accessories',
          },
        ],
      },
      {
        label: 'Women\u0027s Clothing',
        to: '/category/clothing/womens-clothing',
        items: [
          {
            label: 'Jackets & Outerwear',
            to: '/category/clothing/womens-clothing/jackets-and-outerwear',
          },
          {
            label: 'Shirts & Tops',
            to: '/category/clothing/womens-clothing/shirts-and-tops',
          },
          {
            label: 'Pants & Shorts',
            to: '/category/clothing/womens-clothing/pants-and-shorts',
          },
          {
            label: 'Hats',
            to: '/category/clothing/womens-clothing/hats',
          },
          {
            label: 'Accessories',
            to: '/category/clothing/womens-clothing/accessories',
          },
        ],
      },
      {
        label: 'Kids\u0027 Clothing',
        to: '/category/clothing/kids-clothing',
        items: [
          {
            label: 'Jackets & Outerwear',
            to: '/category/clothing/kids-clothing/jackets-and-outerwear',
          },
          {
            label: 'Shirts & Tops',
            to: '/category/clothing/kids-clothing/shirts-and-tops',
          },
          {
            label: 'Pants & Shorts',
            to: '/category/clothing/kids-clothing/pants-and-shorts',
          },
        ],
      },
      {
        label: 'Hunting Clothing',
        to: '/category/clothing/hunting-clothing',
        items: [
          {
            label: 'Jackets & Outerwear',
            to: '/category/clothing/hunting-clothing/jackets-and-outerwear',
          },
          {
            label: 'Shirts & Tops',
            to: '/category/clothing/hunting-clothing/shirts-and-tops',
          },
          {
            label: 'Pants & Shorts',
            to: '/category/clothing/hunting-clothing/pants-and-shorts',
          },
          {
            label: 'Bibs & Coveralls',
            to: '/category/clothing/hunting-clothing/bibs-and-coveralls',
          },
          {
            label: 'Hats',
            to: '/category/clothing/hunting-clothing/hats',
          },
          {
            label: 'Gloves',
            to: '/category/clothing/hunting-clothing/gloves',
          },
          {
            label: 'Accessories',
            to: '/category/clothing/hunting-clothing/accessories',
          },
        ],
      },
      {
        label: 'Footwear',
        to: '/category/clothing/footwear',
        items: [
          {
            label: 'Men\u0027s Shoes & Boots',
            to: '/category/clothing/footwear/mens-shoes-and-boots',
          },
          {
            label: 'Women\u0027s Shoes & Boots',
            to: '/category/clothing/footwear/womens-shoes-and-boots',
          },
          {
            label: 'Kids\u0027 Shoes & Boots',
            to: '/category/clothing/footwear/kids-shoes-and-boots',
          },
          {
            label: 'Socks',
            to: '/category/clothing/footwear/socks',
          },
          {
            label: 'Accessories',
            to: '/category/clothing/footwear/accessories',
          },
        ],
      },
      {
        label: 'KYGUNCO Merchandise',
        to: '/category/clothing/kygunco-merchandise',
        items: [
          {
            label: 'KYGUNCO Clothing',
            to: '/category/clothing/kygunco-merchandise/kygunco-clothing',
          },
          {
            label: 'KYGUNCO Hats',
            to: '/category/clothing/kygunco-merchandise/kygunco-hats',
          },
          {
            label: 'KYGUNCO Accessories',
            to: '/category/clothing/kygunco-merchandise/kygunco-accessories',
          },
        ],
      },
    ],
  },
  {
    label: 'Adventure',
    to: '/category/adventure',
    items: [
      {
        label: 'Camping',
        to: '/category/adventure/camping',
        items: [
          {
            label: 'Camping Tents',
            to: '/category/adventure/camping/camping-tents',
          },
          {
            label: 'Sleeping Bags & Pads',
            to: '/category/adventure/camping/sleeping-bags-and-pads',
          },
          {
            label: 'Cots & Hammocks',
            to: '/category/adventure/camping/cots-and-hammocks',
          },
          {
            label: 'Chairs & Furniture',
            to: '/category/adventure/camping/chairs-and-furniture',
          },
          {
            label: 'Cookware & Camp Stoves',
            to: '/category/adventure/camping/cookware-and-camp-stoves',
          },
          {
            label: 'Dishes & Utensils',
            to: '/category/adventure/camping/dishes-and-utensils',
          },
          {
            label: 'Camp Food',
            to: '/category/adventure/camping/camp-food',
          },
          {
            label: 'Flashlights & Headlamps',
            to: '/category/adventure/camping/flashlights-and-headlamps',
          },
          {
            label: 'Heaters',
            to: '/category/adventure/camping/heaters',
          },
        ],
      },
      {
        label: 'Coolers & Storage',
        to: '/category/adventure/coolers-and-storage',
        items: [
          {
            label: 'Hard Coolers',
            to: '/category/adventure/coolers-and-storage/hard-coolers',
          },
          {
            label: 'Soft Coolers',
            to: '/category/adventure/coolers-and-storage/soft-coolers',
          },
          {
            label: 'Cooler Accessories',
            to: '/category/adventure/coolers-and-storage/cooler-accessories',
          },
          {
            label: 'Storage',
            to: '/category/adventure/coolers-and-storage/storage',
          },
        ],
      },
      {
        label: 'Drinkware & Hydration',
        to: '/category/adventure/drinkware-and-hydration',
        items: [
          {
            label: 'Cups & Tumblers',
            to: '/category/adventure/drinkware-and-hydration/cups-and-tumblers',
          },
          {
            label: 'Hydration Packs',
            to: '/category/adventure/drinkware-and-hydration/hydration-packs',
          },
          {
            label: 'Water Filtration',
            to: '/category/adventure/drinkware-and-hydration/water-filtration',
          },
          {
            label: 'Water Storage',
            to: '/category/adventure/drinkware-and-hydration/water-storage',
          },
        ],
      },
      {
        label: 'Safety & Survival',
        to: '/category/adventure/safety-and-survival',
        items: [
          {
            label: 'Emergency & Survival Kits',
            to: '/category/adventure/safety-and-survival/emergency-and-survival-kits',
          },
          {
            label: 'Power Stations & Solar',
            to: '/category/adventure/safety-and-survival/power-stations-and-solar',
          },
          {
            label: 'Bug & Sun',
            to: '/category/adventure/safety-and-survival/bug-and-sun',
          },
          {
            label: 'Pepper & Bear Spray',
            to: '/category/adventure/safety-and-survival/pepper-and-bear-spray',
          },
          {
            label: 'Compasses & Maps',
            to: '/category/adventure/safety-and-survival/compasses-and-maps',
          },
          {
            label: 'Generators',
            to: '/category/adventure/safety-and-survival/generators',
          },
        ],
      },
      {
        label: 'Boating',
        to: '/category/adventure/boating',
        items: [
          {
            label: 'Kayaks & Canoes',
            to: '/category/adventure/boating/kayaks-and-canoes',
          },
          {
            label: 'Life Jackets',
            to: '/category/adventure/boating/life-jackets',
          },
          {
            label: 'Paddles & Oars',
            to: '/category/adventure/boating/paddles-and-oars',
          },
          {
            label: 'Dry Bags',
            to: '/category/adventure/boating/dry-bags',
          },
          {
            label: 'Storage & Transport',
            to: '/category/adventure/boating/storage-and-transport',
          },
        ],
      },
    ],
  },
  {
    label: 'Home & Gifts',
    to: '/category/home-and-gifts',
    items: [
      {
        label: 'Outdoor Cooking',
        to: '/category/home-and-gifts/outdoor-cooking',
        items: [
          {
            label: 'Grills & Smokers',
            to: '/category/home-and-gifts/outdoor-cooking/grills-and-smokers',
          },
          {
            label: 'Grill Accessories',
            to: '/category/home-and-gifts/outdoor-cooking/grill-accessories',
          },
          {
            label: 'Food Seasonings',
            to: '/category/home-and-gifts/outdoor-cooking/food-seasonings',
          },
        ],
      },
      {
        label: 'Food & Drink',
        to: '/category/home-and-gifts/food-and-drink',
      },
      {
        label: 'Bath & Body',
        to: '/category/home-and-gifts/bath-and-body',
      },
      {
        label: 'Novelties',
        to: '/category/home-and-gifts/novelties',
      },
      {
        label: 'Gift Cards',
        to: '/category/home-and-gifts/gift-cards',
      },
    ],
  },
];
